<template>
    <div class="auth-box">
        <Alert />
        <div class="box">
            <header class="box-header">
                <h2>
                    {{ title }}  
                </h2>
            </header>
            <main>
                <slot></slot>
            </main>
            <footer class="box-footer">
                <slot name="footer"></slot>
            </footer>
        </div>
    </div>  
</template>

<script>
import Alert from "@common/components/Alert";
export default {
    name: "AuthBox",
    components: {
        Alert,
    },
    props: {
        title: String
    }

}
</script>

<style scoped>
.auth-box{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}
.box{
    margin: auto;
    width: 40%;
    background: white;
    padding: 0.4rem;
    box-shadow: 1px 2px 8px 1px var(--border-color);
    font-size: 0.8rem;
    max-width: 35rem;

}
.box-header{
    font-size: 1rem;
    background: rgb(23 117 66);
    background: var(--primary-color);
    background: linear-gradient(-30deg, rgb(23 117 66) 0%, rgb(72 189 125) 33%, rgb(23 117 66) 100%);
    box-shadow: inset 1px 1px 15px 5px #177542;
    color: white;
    color: var(--light-color);
  text-shadow: 1px 1px 6px #103e18;
}
.box-header h2{
    margin: 0;
    padding: 0.5rem 1rem;
}
::v-deep(.table-form){
    margin: 1rem auto;
}
::v-deep(.label){
    text-align: right;
    font-weight: 700;
}

::v-deep(.buttons){
    margin: 1rem auto;
    text-align: center;
}
  
::v-deep(.table-form td){
    padding: 0.3rem;
}
::v-deep(.table-form input){
    padding: 0.3rem;
}
::v-deep(.text-center){
    text-align: center;
}
.box-footer{
    margin: 1rem;
}
.box-footer>::v-deep(div){
    margin: 0.3rem;
}
::v-deep(.form-control){
    min-width: 15rem;
}
::v-deep(.invalid-feedback){
    color: rgb(177, 6, 6);
}

::v-deep(.buttons button){
    width: 20rem;
    max-width: 100%;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
}

@media only screen and (max-width: 1200px) {
    .box{
        width: 60%;
    }
}

@media only screen and (max-width: 1000px) {
    .box{
        width: 80%;
    }
}
@media only screen and (max-width: 600px) {
    .box{
        width: 90%;
    }
    ::v-deep(.table-form input[type="text"]),::v-deep(.table-form input[type="password"]){
        width: 100%;
    }
    
    ::v-deep(.table-form),::v-deep(.table-form td), ::v-deep(.table-form tr)  { 
        display: block; 
    }
    
    ::v-deep(.label){
        text-align: left;
        font-weight: 700;
    }
}

</style>