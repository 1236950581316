<template>
    <AuthBox class="login-page" :title="$t('autentification.login-header')">
        <template v-slot:default>
            <form @submit.prevent="handleSubmit" >
                <table class="table-form">
                    <tr>
                        <td class="label">
                            <label for="username">{{ $t('autentification.username') }}</label>
                        </td>
                        <td>
                            <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><div v-show="submitted && !username" class="invalid-feedback">{{ $t('form.item_required',{item: $t('autentification.username')}) }}</div></td>
                    </tr>
                    <tr>
                        <td class="label">
                            <label htmlFor="password">
                                {{ $t('autentification.password') }} 
                            </label>
                        </td>
                        <td>
                           <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><div v-show="submitted && !password" class="invalid-feedback">{{ $t('form.item_required',{item: $t('autentification.password')}) }}</div></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <input type="checkbox" id="remember_login" v-model="remember_login">
                            <label for="remember_login">{{  $t('autentification.remember_login') }}</label>
                        </td>
                    </tr>
                </table>
                <div class="buttons">
                    <button type="submit" class="btn btn-primary" :disabled="status.loggingIn">
                        <span v-show="!status.loggingIn">{{ $t('autentification.login') }}</span>
                        <BaseLoader v-show="status.loggingIn"/>
                    </button>
                </div>
            </form>
        </template>
    </AuthBox>
</template>

<script>
import AuthBox from "@common/components/auth/AuthBox";
import { useStore } from 'vuex'
import { computed,ref } from 'vue';

export default {
    name: "LoginPage",
    components: {
        AuthBox,
    },
    setup() {
        const store = useStore();

        store.dispatch("account/logout");

        const username = ref('');
        const password = ref('');

        const remember_login = ref(false);

        const status = computed(() => store.getters["account/status"]);

        const submitted = ref(false);
        const handleSubmit = () => {
            submitted.value = true;
            if (username.value && password.value) {
                store.dispatch("account/login",{ username: username.value, password: password.value,remember_login: remember_login.value })
            }
        }

        return {
            username,
            password,
            status,
            submitted,
            handleSubmit,
            remember_login
        }
    }
}
</script>

<style scoped>
.buttons button{
    width: 20rem;
    max-width: 100%;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
}
.forgot_password{
    text-align: right;
}



</style>
